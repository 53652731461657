import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { purchaseTokens } from './lib/stripe';

interface StoreState {
  localFiles: {
    resume: { content: string; name: string } | null;
    coverLetterSample: { content: string; name: string } | null;
  };
  tokens: number;
  setLocalFile: (type: 'resume' | 'coverLetterSample', content: string, name: string) => void;
  clearLocalFile: (type: 'resume' | 'coverLetterSample') => void;
  startTokenPurchase: (productId: string) => Promise<void>;
  addTokens: (amount: number) => void;
  useToken: () => boolean;
}

export const useStore = create<StoreState>()(
  persist(
    (set, get) => ({
      localFiles: {
        resume: null,
        coverLetterSample: null
      },
      tokens: 0,
      setLocalFile: (type, content, name) => 
        set(state => ({
          localFiles: {
            ...state.localFiles,
            [type]: { content, name }
          }
        })),
      clearLocalFile: (type) =>
        set(state => ({
          localFiles: {
            ...state.localFiles,
            [type]: null
          }
        })),
      startTokenPurchase: async (productId: string) => {
        try {
          await purchaseTokens({
            productId,
            successUrl: `${window.location.origin}/purchase-success?product=${productId}`,
            cancelUrl: `${window.location.origin}/`
          });
        } catch (error) {
          console.error('Error starting token purchase:', error);
          throw error;
        }
      },
      addTokens: (amount) => {
        set(state => {
          const newTotal = state.tokens + amount;
          // Store the token balance in localStorage as a backup
          localStorage.setItem('token_balance', newTotal.toString());
          return { tokens: newTotal };
        });
      },
      useToken: () => {
        const state = get();
        if (state.tokens > 0) {
          const newTotal = state.tokens - 1;
          set({ tokens: newTotal });
          // Update backup storage
          localStorage.setItem('token_balance', newTotal.toString());
          return true;
        }
        return false;
      }
    }),
    {
      name: 'quickapply-storage',
      partialize: (state) => ({
        localFiles: state.localFiles,
        tokens: state.tokens,
      }),
    }
  )
);