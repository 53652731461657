import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { db } from '../lib/firebase';
import type { Feedback } from '../types/feedback';

interface FeedbackInput {
  message: string;
  type: Feedback['type'];
  userId: string;
  email?: string;
}

export async function submitFeedback(input: FeedbackInput): Promise<string> {
  try {
    const feedbackRef = await addDoc(collection(db, 'feedback'), {
      id: nanoid(),
      message: input.message,
      type: input.type,
      userId: input.userId,
      email: input.email,
      status: 'new',
      createdAt: serverTimestamp()
    });

    return feedbackRef.id;
  } catch (error) {
    console.error('Error submitting feedback:', error);
    throw new Error('Failed to submit feedback. Please try again.');
  }
}