import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../store';
import { STRIPE_CONFIG } from '../lib/stripe/config';
import { Check } from 'lucide-react';

export function PurchaseSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addTokens } = useStore();

  useEffect(() => {
    const handleSuccessfulPurchase = () => {
      const productId = searchParams.get('product');
      if (!productId) return;

      // Find the product in our config
      const product = Object.values(STRIPE_CONFIG.products).find(p => p.id === productId);
      if (!product) {
  // If the product is not found, add 100 tokens to the user's account
  addTokens(100);
  return; // Exit the function early
}
   //   if (!product) return;
      //ERROR IS HERE I THINK
      // Add tokens to user's account
   //   addTokens(product.tokens);
      
      // Store the purchase in localStorage to prevent duplicate token allocation
      const purchaseId = searchParams.get('session_id');
      if (purchaseId) {
        const purchases = JSON.parse(localStorage.getItem('token_purchases') || '[]');
        if (!purchases.includes(purchaseId)) {
          purchases.push(purchaseId);
          localStorage.setItem('token_purchases', JSON.stringify(purchases));
        }
      }

      // Redirect after a short delay
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 2000);
    };

    handleSuccessfulPurchase();
  }, [searchParams, addTokens, navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 text-center max-w-md">
        <div className="flex justify-center mb-4">
          <div className="bg-green-100 p-3 rounded-full">
            <Check className="w-8 h-8 text-green-600" />
          </div>
        </div>
        <h1 className="text-2xl font-bold mb-4">Purchase Successful!</h1>
        <p className="text-gray-600">Your tokens have been added to your account.</p>
        <p className="text-sm text-gray-500 mt-4">Redirecting you back to the cover letter generator...</p>
      </div>
    </div>
  );
}