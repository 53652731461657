export const STRIPE_CONFIG = {
  publicKey: 'pks_live_51QPqdKDsyGVnOZHc7ZET9dtbYGFEpnPOYcvkk6OLqFsZVXuE6EWA07300ycMOZcv4BoLB7zGcJHdxlxHBikOJzSG00RtkQ9r9n',
  products: {
    basic: {
      id: 'prod_RJx9OPgJ46bZdA',
      //'plink_1QQiWZDsyGVnOZHctqsTkJYd'
      name: 'Basic Pack',
      tokens: 100,
      price: 9.99,
      paymentLink: 'https://buy.stripe.com/dR6g1RfqA0Uu0c8cMP'
    },
    pro: {
      id: 'prod_RJKCZ5D5z7NQ5Q',
      name: 'Pro Pack',
      tokens: 300,
      price: 19.99,
      paymentLink: 'https://buy.stripe.com/7sI02T1zK46G1gc7st?prefilled_email={email}&client_reference_id={userId}'
    }
  }
} as const;

export type ProductId = keyof typeof STRIPE_CONFIG.products;