import React, { useState } from 'react';
import { X, Coins, Check, Loader2, AlertCircle } from 'lucide-react';
import { STRIPE_CONFIG } from '../lib/stripe/config';
import { TokenRecoveryModal } from './TokenRecoveryModal';

interface TokenPurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TokenPurchaseModal({ isOpen, onClose }: TokenPurchaseModalProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);

  const handlePurchase = async (productId: string) => {
    try {
      setIsProcessing(true);
      setError(null);
      const product = STRIPE_CONFIG.products[productId as keyof typeof STRIPE_CONFIG.products];
      if (product) {
        window.location.href = product.paymentLink;
      } else {
        throw new Error('Invalid product selection');
      }
    } catch (err) {
      console.error('Purchase error:', err);
      setError(err instanceof Error ? err.message : 'Failed to start purchase process');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  const { basic, pro } = STRIPE_CONFIG.products;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            disabled={isProcessing}
          >
            <X className="w-5 h-5" />
          </button>
          
          <div className="p-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold mb-2">Purchase Tokens</h2>
              <p className="text-gray-600">Choose a token package to get started</p>
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-sm">
                {error}
              </div>
            )}

            <div className="space-y-4">
              <button
                onClick={() => handlePurchase('basic')}
                disabled={isProcessing}
                className="w-full p-4 border rounded-lg hover:border-primary-500 hover:bg-primary-50 transition-all group disabled:opacity-50"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-lg font-semibold group-hover:text-primary-600">{basic.name}</span>
                  <span className="text-lg font-bold text-primary-600">${basic.price}</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <Coins className="w-4 h-4" />
                  <span>{basic.tokens} Tokens</span>
                </div>
              </button>

              <div className="relative">
                <div className="absolute -top-3 right-3 bg-green-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                  Best Value
                </div>
                <button
                  onClick={() => handlePurchase('pro')}
                  disabled={isProcessing}
                  className="w-full p-4 border-2 border-primary-500 rounded-lg bg-primary-50 hover:bg-primary-100 transition-all group disabled:opacity-50"
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-2">
                      <span className="text-lg font-semibold text-primary-700">{pro.name}</span>
                      <span className="bg-primary-100 text-primary-700 px-2 py-0.5 rounded text-sm">
                        Popular
                      </span>
                    </div>
                    <span className="text-lg font-bold text-primary-700">${pro.price}</span>
                  </div>
                  <div className="flex items-center gap-2 text-primary-600">
                    <Coins className="w-4 h-4" />
                    <span>{pro.tokens} Tokens</span>
                  </div>
                  <div className="mt-2 flex items-start gap-2 text-sm text-primary-700">
                    <Check className="w-4 h-4 mt-0.5 flex-shrink-0" />
                    <span>Save ${(basic.price * 3 - pro.price).toFixed(2)} compared to Basic Pack</span>
                  </div>
                </button>
              </div>
            </div>

            <button
              onClick={() => setShowRecoveryModal(true)}
              className="w-full mt-6 text-primary-600 hover:text-primary-700 text-sm"
            >
              Previously purchased but didn't receive tokens?
            </button>

            {isProcessing && (
              <div className="mt-4 flex items-center justify-center gap-2 text-primary-600">
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Processing your purchase...</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <TokenRecoveryModal
        isOpen={showRecoveryModal}
        onClose={() => setShowRecoveryModal(false)}
      />
    </>
  );
}