import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY || import.meta.env.VITE_STRIPE_API_KEY, // Properly assign the key
  dangerouslyAllowBrowser: true
});


export async function generateCoverLetter(
  resumeText: string,
  jobDescription: string,
  companyName: string = '',
  coverLetterSample?: string | null
): Promise<string> {
  try {
    const prompt = `Generate a professional cover letter based on the provided resume and job description.
Use only the skills and experience mentioned in the resume.
The cover letter should be specific, engaging, and tailored to the role.
Do not include any placeholders or filler text.

Resume:
${resumeText}

Job Description:
${jobDescription}
${companyName ? `\nCompany: ${companyName}` : ''}
${coverLetterSample ? `\nStyle reference (emulate this tone and structure):\n${coverLetterSample}` : ''}

Instructions:
- Use natural, professional language
- Highlight relevant experience and skills from the resume
- Make specific connections to the job requirements
- Keep the length appropriate for a cover letter
- Do not include any placeholders like [Name] or [Position]
- Include a proper salutation and closing`;

    const completion = await openai.chat.completions.create({
      model: 'gpt-4',
      messages: [
        {
          role: 'system',
          content: 'You are an expert cover letter writer who creates highly personalized and compelling cover letters. You never use placeholders or filler text.'
        },
        {
          role: 'user',
          content: prompt
        }
      ],
      temperature: 0.7,
      max_tokens: 1000
    });

    return completion.choices[0]?.message?.content || '';
  } catch (error) {
    console.error('Error generating cover letter:', error);
    throw error;
  }
}