import React from 'react';
import { X } from 'lucide-react';
import { AuthForm } from './auth/AuthForm';

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: 'signin' | 'signup';
}

export function SignInModal({ isOpen, onClose, initialMode = 'signin' }: SignInModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md relative my-8">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-5 h-5" />
        </button>
        <div className="p-6">
          <AuthForm initialMode={initialMode} />
        </div>
      </div>
    </div>
  );
}