import mammoth from 'mammoth/mammoth.browser';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

async function extractDOCText(file: File): Promise<string> {
  const arrayBuffer = await file.arrayBuffer();
  const result = await mammoth.extractRawText({ arrayBuffer });
  return result.value.trim();
}

async function extractTXTText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve((e.target?.result as string).trim());
    reader.onerror = () => reject(new Error('Failed to read text file'));
    reader.readAsText(file);
  });
}

export async function extractText(file: File): Promise<string> {
  try {
    // Basic validation
    if (!file) {
      throw new Error('No file provided');
    }

    if (file.size === 0) {
      throw new Error('File is empty');
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File is too large. Maximum size is 10MB.');
    }

    // Extract text based on file type
    if (
      file.type === 'application/msword' || 
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.name.endsWith('.doc') || 
      file.name.endsWith('.docx')
    ) {
      return await extractDOCText(file);
    }
    
    if (file.type === 'text/plain' || file.name.endsWith('.txt')) {
      return await extractTXTText(file);
    }

    throw new Error('Unsupported file type. Please upload a TXT, DOC, or DOCX file.');
  } catch (error) {
    console.error('Error extracting text:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to read file');
  }
}