import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CoverLetterWriter } from './components/CoverLetterWriter';
import { Header } from './components/Header';
import { FloatingFeedbackButton } from './components/feedback/FloatingFeedbackButton';
import { PurchaseSuccess } from './components/PurchaseSuccess';
import { useAuth } from './hooks/useAuth';

export default function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-600 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Header user={user} />
        <main className="pt-16">
          <Routes>
            <Route path="/purchase-success" element={<PurchaseSuccess />} />
            <Route path="/" element={<CoverLetterWriter user={user} />} />
          </Routes>
        </main>
        <FloatingFeedbackButton />
      </div>
    </Router>
  );
}