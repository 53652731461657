import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDNqcbp5co94otWioYFP6t5JqQea5hnPI0",
  authDomain: "prepd-7b489.firebaseapp.com",
  projectId: "prepd-7b489",
  storageBucket: "prepd-7b489.appspot.com",
  messagingSenderId: "141425811876",
  appId: "1:141425811876:web:cf1e401d2c43d18fac6131"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Set persistence to local
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting auth persistence:', error);
});

// Connect to emulators in development
if (import.meta.env.DEV && import.meta.env.VITE_FIREBASE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

export { auth, db };