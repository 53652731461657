import React, { useState } from 'react';
import { FileText, Download, Loader2, AlertCircle, Coins } from 'lucide-react';
import { generateCoverLetter } from '../lib/openai';
import { extractText } from '../lib/fileHandlers';
import { useStore } from '../store';
import { SignInModal } from './SignInModal';
import { TokenPurchaseModal } from './TokenPurchaseModal';
import type { User } from 'firebase/auth';

interface CoverLetterWriterProps {
  user: User | null;
}

export function CoverLetterWriter({ user }: CoverLetterWriterProps) {
  const { localFiles, setLocalFile, clearLocalFile, tokens, useToken } = useStore();
  const [jobDescription, setJobDescription] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [generatedLetter, setGeneratedLetter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);

  const handleFileUpload = async (type: 'resume' | 'coverLetterSample', file: File) => {
    try {
      const content = await extractText(file);
      setLocalFile(type, content, file.name);
      setError(null);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to read file';
      setError(message);
    }
  };

  const handleGenerate = async () => {
    if (!user) {
      setShowSignInModal(true);
      return;
    }

    if (tokens === 0) {
      setShowTokenModal(true);
      return;
    }

    if (!localFiles.resume) {
      setError('Please upload your resume first');
      return;
    }

    if (!jobDescription.trim()) {
      setError('Please provide a job description');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const letter = await generateCoverLetter(
        localFiles.resume.content,
        jobDescription,
        companyName,
        localFiles.coverLetterSample?.content
      );

      if (letter) {
        useToken(); // Deduct one token
        setGeneratedLetter(letter);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate cover letter';
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (!generatedLetter) return;

    const blob = new Blob([generatedLetter], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `cover-letter-${companyName || 'company'}.txt`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400">
      <div className="max-w-4xl mx-auto p-4 pt-8">
        <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-xl border border-white/20 p-8 mb-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Cover Letter Generator</h2>
            <div className="flex items-center gap-2 text-primary-600">
              <Coins className="w-5 h-5" />
              <span className="font-medium">{tokens} Tokens</span>
            </div>
          </div>

          <div className="grid gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Resume
              </label>
              {localFiles.resume ? (
                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                  <span className="text-sm text-gray-600 truncate max-w-[80%]">
                    {localFiles.resume.name}
                  </span>
                  <button
                    onClick={() => clearLocalFile('resume')}
                    className="text-red-600 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-primary-300 transition-colors">
                  <input
                    type="file"
                    accept=".txt,.doc,.docx,.pdf"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleFileUpload('resume', file);
                    }}
                    className="hidden"
                    id="resume-upload"
                  />
                  <label
                    htmlFor="resume-upload"
                    className="cursor-pointer text-primary-600 hover:text-primary-700"
                  >
                    Upload Resume
                  </label>
                  <p className="text-sm text-gray-500 mt-1">
                    PDF, DOC, DOCX, or TXT files
                  </p>
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Sample Cover Letter (Optional)
              </label>
              {localFiles.coverLetterSample ? (
                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                  <span className="text-sm text-gray-600 truncate max-w-[80%]">
                    {localFiles.coverLetterSample.name}
                  </span>
                  <button
                    onClick={() => clearLocalFile('coverLetterSample')}
                    className="text-red-600 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-primary-300 transition-colors">
                  <input
                    type="file"
                    accept=".txt,.doc,.docx,.pdf"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleFileUpload('coverLetterSample', file);
                    }}
                    className="hidden"
                    id="sample-upload"
                  />
                  <label
                    htmlFor="sample-upload"
                    className="cursor-pointer text-primary-600 hover:text-primary-700"
                  >
                    Upload Sample
                  </label>
                  <p className="text-sm text-gray-500 mt-1">
                    PDF, DOC, DOCX, or TXT files
                  </p>
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Company Name (Optional)
              </label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 bg-white"
                placeholder="Enter company name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Job Description
              </label>
              <textarea
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 bg-white"
                rows={6}
                placeholder="Paste the job description here"
              />
            </div>

            {error && (
              <div className="p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
                <AlertCircle className="w-5 h-5 flex-shrink-0" />
                <p className="text-sm">{error}</p>
              </div>
            )}

            <button
              onClick={handleGenerate}
              disabled={isLoading || !jobDescription.trim()}
              className="w-full py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 flex items-center justify-center gap-2 shadow-lg"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <FileText className="w-5 h-5" />
                  Generate Cover Letter (1 Token)
                </>
              )}
            </button>
          </div>
        </div>

        {generatedLetter && (
          <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-xl border border-white/20 p-8 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Generated Cover Letter</h2>
              <button
                onClick={handleDownload}
                className="px-3 py-1 text-primary-600 hover:bg-primary-50 rounded-lg flex items-center gap-1"
              >
                <Download className="w-4 h-4" />
                Download
              </button>
            </div>
            <div className="prose max-w-none">
              <pre className="whitespace-pre-wrap font-sans">{generatedLetter}</pre>
            </div>
          </div>
        )}

        <SignInModal 
          isOpen={showSignInModal} 
          onClose={() => setShowSignInModal(false)} 
        />

        <TokenPurchaseModal
          isOpen={showTokenModal}
          onClose={() => setShowTokenModal(false)}
        />
      </div>
    </div>
  );
}