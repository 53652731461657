import React, { useState } from 'react';
import { LogOut, Crown, User, LogIn, UserPlus, Zap, Clock, Sparkles, Coins } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useStore } from '../store';
import { SignInModal } from './SignInModal';
import { TokenPurchaseModal } from './TokenPurchaseModal';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

export function Sidebar({ isOpen, onClose, user }: SidebarProps) {
  const { tokens, startUpgrade } = useStore();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      onClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleAuthClick = (mode: 'signin' | 'signup') => {
    setAuthMode(mode);
    setShowSignInModal(true);
  };

  return (
    <>
      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-white border-r transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {user ? (
          <>
            <div className="p-4 border-b">
              <div className="flex items-center gap-2">
                <User className="w-5 h-5 text-gray-600 flex-shrink-0" />
                <span className="font-medium text-gray-900 truncate max-w-[200px]" title={user?.email}>
                  {user?.email}
                </span>
              </div>
            </div>

            <div className="p-4">
              <div className="mb-6">
                <h3 className="text-sm font-medium text-gray-500 mb-2">Available Tokens</h3>
                <div className="bg-primary-50 rounded-lg p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <Coins className="w-5 h-5 text-primary-600" />
                    <span className="font-medium text-primary-900">{tokens} Tokens</span>
                  </div>
                  <p className="text-sm text-primary-700 mb-3">
                    Each cover letter generation costs 1 token
                  </p>
                  <button
                    onClick={() => setShowTokenModal(true)}
                    className="w-full px-3 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors text-sm font-medium"
                  >
                    Purchase Tokens
                  </button>
                </div>
              </div>

              <button
                onClick={handleSignOut}
                className="w-full px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors flex items-center gap-2 mt-4"
              >
                <LogOut className="w-5 h-5" />
                Sign Out
              </button>
            </div>
          </>
        ) : (
          <div className="p-4">
            <div className="space-y-3">
              <button
                onClick={() => handleAuthClick('signin')}
                className="w-full px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors flex items-center gap-2 justify-center"
              >
                <LogIn className="w-5 h-5" />
                Sign In
              </button>
              <button
                onClick={() => handleAuthClick('signup')}
                className="w-full px-4 py-2 bg-white text-primary-600 border border-primary-200 rounded-lg hover:bg-primary-50 transition-colors flex items-center gap-2 justify-center"
              >
                <UserPlus className="w-5 h-5" />
                Create Account
              </button>
            </div>

            <div className="mt-6 pt-6 border-t">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-3">Why QuickApply?</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2 text-sm text-gray-600">
                    <Zap className="w-4 h-4 text-primary-600 flex-shrink-0" />
                    Generate cover letters in minutes
                  </li>
                  <li className="flex items-center gap-2 text-sm text-gray-600">
                    <Clock className="w-4 h-4 text-primary-600 flex-shrink-0" />
                    Save hours of writing time
                  </li>
                  <li className="flex items-center gap-2 text-sm text-gray-600">
                    <Sparkles className="w-4 h-4 text-primary-600 flex-shrink-0" />
                    AI-powered personalization
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
      )}

      <SignInModal
        isOpen={showSignInModal}
        onClose={() => {
          setShowSignInModal(false);
          onClose();
        }}
        initialMode={authMode}
      />

      <TokenPurchaseModal
        isOpen={showTokenModal}
        onClose={() => setShowTokenModal(false)}
      />
    </>
  );
}