import { loadStripe } from '@stripe/stripe-js';

export const STRIPE_CONFIG = {
  publicKey: //'pk_test_51QPqdKDsyGVnOZHckJ1rYGMTU7MEdERnzo9MgMN39RuLgot77MsdGWXg7oxe3aJARavx9tNdL39HKhdw4oSMYiYO00E60qCv3x'
    'pk_test_51QPqdKDsyGVnOZHckJ1rYGMTU7MEdERnzo9MgMN39RuLgot77MsdGWXg7oxe3aJARavx9tNdL39HKhdw4oSMYiYO00E60qCv3x',
  products: {
    basic: {
      id: 'price_test_basic',
      name: 'Basic Pack',
      tokens: 100,
      price: 9.99,
      paymentLink: 'https://buy.stripe.com/test/4gw9Dtdisbz8f729AA'
    },
    pro: {
      id: 'price_test_pro',
      name: 'Pro Pack',
      tokens: 300,
      price: 19.99,
      paymentLink: 'https://buy.stripe.com/test/7sI02T1zK46G1gc7st'
    }
  }
} as const;

// Initialize Stripe outside of functions to prevent multiple instances
let stripePromise: Promise<any> | null = null;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_CONFIG.publicKey);
  }
  return stripePromise;
};

export interface PurchaseTokensOptions {
  productId: string;
  successUrl: string;
  cancelUrl: string;
}

export interface StripeProduct {
  id: string;
  name: string;
  tokens: number;
  price: number;
  paymentLink: string;
}

export async function purchaseTokens({ productId }: PurchaseTokensOptions) {
  try {
    const product = STRIPE_CONFIG.products[productId as keyof typeof STRIPE_CONFIG.products];
    if (!product) {
      throw new Error('Invalid product selection');
    }
    
    window.location.href = product.paymentLink;
  } catch (error) {
    console.error('Error initiating token purchase:', error);
    throw new Error('Failed to start purchase process. Please try again.');
  }
}

export type ProductId = keyof typeof STRIPE_CONFIG.products;