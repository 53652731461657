import React, { useState } from 'react';
import { X, Loader2, AlertCircle, Check } from 'lucide-react';
import { STRIPE_CONFIG } from '../lib/stripe/config';
import { useStore } from '../store';

interface TokenRecoveryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TokenRecoveryModal({ isOpen, onClose }: TokenRecoveryModalProps) {
  const [productId, setProductId] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const { addTokens } = useStore();

  const handleRecovery = async () => {
    try {
      setIsProcessing(true);
      setError(null);
      
      const product = Object.values(STRIPE_CONFIG.products).find(p => p.id === productId);
      if (!product) {
        throw new Error('Invalid purchase ID. Please check and try again.');
      }

      addTokens(product.tokens);
      setSuccess(true);
      
      setTimeout(() => {
        onClose();
        setSuccess(false);
        setProductId('');
      }, 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to recover tokens');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Recover Previous Purchase</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            disabled={isProcessing}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {!success ? (
            <>
              <p className="text-sm text-gray-600">
                If you previously purchased tokens but didn't receive them, enter your purchase ID below to recover them.
                You can find your purchase ID in your email receipt from Stripe.
              </p>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Purchase ID
                </label>
                <input
                  type="text"
                  value={productId}
                  onChange={(e) => setProductId(e.target.value)}
                  placeholder="e.g., plink_1QQiXBDsyGVnOZHcnssUGYXa"
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  disabled={isProcessing}
                />
              </div>

              {error && (
                <div className="p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
                  <AlertCircle className="w-5 h-5 flex-shrink-0" />
                  <p className="text-sm">{error}</p>
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                  disabled={isProcessing}
                >
                  Cancel
                </button>
                <button
                  onClick={handleRecovery}
                  disabled={isProcessing || !productId.trim()}
                  className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 flex items-center gap-2"
                >
                  {isProcessing ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    'Recover Tokens'
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="text-center py-4">
              <div className="flex justify-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                  <Check className="w-8 h-8 text-green-600" />
                </div>
              </div>
              <h3 className="text-lg font-semibold mb-2">Tokens Recovered!</h3>
              <p className="text-gray-600">Your tokens have been added to your account.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}